<template>
  <div :class="['talent', { te: !talentData.hasApplied }]">
    <div class="top_box">
      <div class="talent-t">
        <div class="talent-tl">
          <div class="head">
            <div class="img">
              <img
                :src="
                  talentData.avatar
                    ? talentData.avatar
                    : require('@/assets/img/default_avatar.png')
                "
                alt=""
              />
            </div>
            <div class="name">{{ talentData.userName }}</div>
          </div>
          <div class="label">
            <div
              class="item"
              v-for="(item, index) in talentData.talentTags"
              :key="index"
            >
              {{ item }}
            </div>
            <div
              class="nodata"
              v-if="talentData.talentTags && talentData.talentTags.length == 0"
            >
              暂未申请达人，赶快来申请吧~
            </div>
          </div>
        </div>
        <div class="talent-tr">
          <!-- <img
          v-if="talentData.talentRank"
          :src="returnModal(talentData.talentRank)"
          alt=""
        /> -->
        </div>
      </div>
    </div>
    <div class="block"><img src="./img/withe.png" alt="" /></div>
    <div class="talent-b">
      <div
        v-for="(item, index) in talentData.talentTypes"
        :key="index"
        :class="['item', { teList: item.remark }]"
        @click="toApplyTalent(item)"
      >
        <div :class="['item-t', item.backClass]">
          <div class="item-tl">
            <div class="title" style="color: #000">
              <div>{{ item.talentTypeName }}</div>
              <img
                @click.stop="showDetail(item.remark)"
                src="./img/detail.png"
                alt=""
              />
            </div>
            <div
              :class="['status', item.class]"
              v-if="item.talentAuditStatus != 1"
            >
              {{ item.statusTxt }}
            </div>
            <div
              :class="['status', item.class]"
              v-if="item.talentAuditStatus == 1"
              @click="addActivity()"
            >
              发布活动
            </div>
            <div
              :class="['status', 'created']"
              v-show="item.talentAuditStatus == 1"
              @click.stop="createCommunity()"
            >
              {{ userCreateAssStatus == false ? "创建社群" : "审核中" }}
            </div>
          </div>
          <div class="item-tr">
            <img :src="item.url" alt="" />
          </div>
        </div>
        <!-- <div class="item-b" v-if="item.remark">
          <div class="item-bc">{{ item.remark }}</div>
        </div> -->
      </div>
    </div>
    <!-- <div class="bottom" @click="toPartNer">达人风采</div> -->
    <talentDialog
      v-if="!talentData.hasApplied && dialogFlag == 1"
      @closeTalent="closeTalent"
    ></talentDialog>
    <v-dialog
      confirmButtonColor="#397ff9"
      confirmButtonText="关闭"
      :showCancelButton="false"
      v-model="isDialog_success"
      @confirm="confirm_success"
    >
      <p class="transfer_success">{{ typeDetail }}</p>
    </v-dialog>
  </div>
</template>

<script>
import talentDialog from "./talentDialog";
import { talentUrl, getUserAssStatusUrl } from "./api.js";
import { talentLabel, modalList } from "./map.js";
import { getOption } from "@/utils/utils.js";
import { gloabalCount, toRegisterWT } from "@/utils/common.js";
var moment = require("moment");
export default {
  name: "talent",
  data() {
    return {
      isDialog_success: false,
      typeDetail: "",
      form: {
        donatePic: "",
        donateContent: "",
      },
      talentData: {
        hasApplied: true,
      },
      talentLabel,
      modalList,
      isDialog: true,
      userCreateAssStatus: null,
      dialogFlag: null,
    };
  },
  components: {
    talentDialog,
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    if (sessionStorage.getItem("dialogFlag")) {
      this.dialogFlag = Number(sessionStorage.getItem("dialogFlag"));
    } else {
      this.dialogFlag = 1;
    }
  },
  async mounted() {
    this.getTalent();
    this.checkUserCreateAssStatus();
    gloabalCount("", 6, 1);
  },
  beforeDestroy() {
    console.log("beforeDestroy");
  },
  destroyed() {
    sessionStorage.setItem("dialogFlag", 0);
    console.log("destroyed");
  },
  methods: {
    confirm_success() {
      this.isDialog_success = false;
    },
    showDetail(str) {
      this.typeDetail = str;
      this.isDialog_success = true;
    },
    checkUserCreateAssStatus() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios
        .get(`${getUserAssStatusUrl}`, { params: params })
        .then((res) => {
          if (res.code === 200) {
            this.userCreateAssStatus = res.data;
          }
        });
    },
    toPartNer() {
      this.$router.push({
        name: "partnerStyleList",
      });
    },
    closeTalent() {
      this.talentData.hasApplied = true;
      this.dialogFlag = 0;
    },
    async createCommunity() {
      if (this.userCreateAssStatus == true) {
        this.$toast("社群创建审核中！");
      } else {
        if (await toRegisterWT(2)) {
          this.$router.push({
            name: "createCommunity",
          });
        }
      }
    },
    addActivity() {
      this.$router.push({
        name: "publishActivity",
      });
      sessionStorage.setItem("dialogFlag", 0);
    },
    async toApplyTalent(item) {
      if (await toRegisterWT(2)) {
        if (item.talentAuditStatus == 1) {
          this.$router.push({
            name: "publishActivity",
          });
          sessionStorage.setItem("dialogFlag", 0);
        } else {
          let query = {
            talentType: item.talentTypeName,
          };
          if (item.talentAuditId) {
            query = {
              talentType: item.talentTypeName,
              id: item.talentAuditId,
            };
          }
          this.$router.push({
            name: "applyTalent",
            query: query,
          });
          sessionStorage.setItem("dialogFlag", 0);
        }
      }
    },
    returnModal(status) {
      if (status) {
        return getOption(status, modalList, "key")["url"];
      } else {
        return modalList[0]["url"];
      }
    },
    toList() {
      this.$router.push({
        name: "pointsList",
      });
      sessionStorage.setItem("dialogFlag", 0);
    },

    getTalent() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios
        .get(`${talentUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data) {
              this.talentData = res.data;
              if (this.talentData.avatar) {
                this.talentData.avatar = this.talentData.avatar.ossResize(
                  96,
                  96
                );
              }
              if (
                this.talentData.talentTypes &&
                this.talentData.talentTypes.length != 0
              ) {
                this.talentData.talentTypes.forEach((element) => {
                  if (element.talentAuditStatus === "") {
                    element.statusTxt = "立即申请";
                    element.class = "toApply";
                  }
                  if (element.talentAuditStatus === 0) {
                    element.statusTxt = "审核中";
                    element.class = "applyIng";
                  }
                  if (element.talentAuditStatus === 1) {
                    element.statusTxt = "申请成功";
                    element.class = "applyEd";
                  }
                  if (element.talentAuditStatus === 2) {
                    element.statusTxt = "申请失败";
                    element.class = "applyFail";
                  }
                  if (element.talentType == "talentType1") {
                    element.backClass = "talent0";
                    element.url = require("./img/undraw_Working_re.png");
                  }
                  if (element.talentType == "talentType2") {
                    element.backClass = "talent1";
                    element.url = require("./img/undraw_art_muse.png");
                  }
                  if (element.talentType == "talentType3") {
                    element.backClass = "talent2";
                    element.url = require("./img/undraw_book_love.png");
                  }
                  if (element.talentType == "talentType4") {
                    element.backClass = "talent3";
                    element.url = require("./img/undraw_creative_team.png");
                  }
                });
              } else {
                this.talentData.talentTypes = [];
              }
            }
          } else {
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.talent {
  min-height: 100vh;
  overflow: auto;
  // background: #f5f5f5;
  padding-bottom: 99px;
  box-sizing: border-box;
  .transfer_success {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    padding: 45px 20px;
    box-sizing: border-box;
  }

  &.te {
    overflow: hidden;
  }

  .block {
    z-index: 1;
    margin-top: -70px;
    width: 100%;
    height: 111px;
    // background-color: pink;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .top_box {
    width: 100%;
    padding: 0 48px;
    box-sizing: border-box;
    height: 306px;
    position: relative;
    z-index: -1;

    .talent-t {
      height: 100%;
      background: url("./img/back.png") no-repeat;
      // background-color: #f9f9f9;
      background-size: 100% 100%;
      padding: 8px 40px 0 32px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      .talent-tl {
        padding-top: 34px;
        width: 460px;

        .head {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .img {
            width: 110px;
            height: 110px;
            border-radius: 50%;
            margin-right: 20px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .name {
            word-break: break-all;
            width: 280px;
            font-size: 36px;
            line-height: 34px;
            color: rgba(0, 0, 0, 0.85);
          }
        }

        .label {
          .item {
            display: inline-block;
            padding: 0 10px;
            margin: 0 10px 10px 0;
            text-align: center;
            box-sizing: border-box;
            border-radius: 10px;
            border: 1px solid #fff;
            color: #fff;
            font-size: 24px;
          }

          .nodata {
            color: #fff;
            font-size: 24px;
          }
        }
      }

      .talent-tr {
        width: 134px;
        height: 232px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .talent-b {
    z-index: 2;
    box-sizing: border-box;
    padding: 28px;
    padding-top: 0;
    width: 100%;
    // height: 100%;
    // box-shadow: 0px 0px 22px 0px rgba(173, 210, 255, 0.41);
    // border-radius: 100px 50px 0 0;

    .item {
      height: 400px;
      border-radius: 8px;
      margin-bottom: 24px;

      &.teList {
        height: 256px;
      }

      .item-t {
        display: flex;
        border-radius: 16px 16px 0 0;
        box-sizing: border-box;
        background: #f8d8d6;
        height: 256px;
        padding: 10px 18px 0 48px;
        padding-top: 0;
        justify-content: space-between;

        .item-tl {
          padding-top: 30px;

          .title {
            font-size: 40px;
            // line-height: 60px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            div {
              white-space: nowrap;
            }
            img {
              margin-left: 5px;
              width: 35px;
            }
          }

          .status {
            width: 174px;
            height: 56px;
            text-align: center;
            background: linear-gradient(180deg, #72a7ff 0%, #317dff 100%);
            font-size: 30px;
            line-height: 56px;
            border-radius: 16px;
            font-weight: 600;
            color: #fff;
            box-sizing: border-box;
            // border: 2px solid;
          }

          .applyEd {
            background: linear-gradient(180deg, #72a7ff 0%, #317dff 100%);
          }

          .applyIng {
            background: none;
            border: 3px solid #625eff;
            color: #625eff;
          }

          .applyFail {
            background: none;
            border: 1px solid red;
            color: red;
          }

          .created {
            margin-top: 12px;
            background: linear-gradient(180deg, #9591ff 0%, #5f5bff 100%);
          }
        }

        .item-tr {
          width: 400px;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      &.teList {
        .item-t {
          border-radius: 16px;
        }
      }

      .talent1,
      .talent2,
      .talent3 {
        .item-tl {
          .title {
            color: #fff;
          }
        }
      }

      .talent1 {
        background: #bedfff;
      }

      .talent2 {
        background: #b7dcc1;

        .item-tr {
          width: 400px;
          text-align: center;

          img {
            width: 302px;
          }
        }
      }

      .talent3 {
        background: #ffe08c;
      }

      .item-b {
        height: 144px;
        background: #fff;
        box-sizing: border-box;
        border-radius: 0 0 16px 16px;
        box-sizing: border-box;
        padding: 20px 16px;
        font-size: 32px;
        color: #666;
        line-height: 36px;

        .item-bc {
          height: 100%;
          overflow: scroll;
        }
      }
    }
  }

  .bottom {
    width: 550px;
    height: 66px;
    margin: 0 auto;
    background: linear-gradient(180deg, #73a8ff 0%, #307cff 100%);
    text-align: center;
    font-weight: bold;
    border-radius: 10px;
    font-size: 30px;
    color: #ffffff;
    line-height: 66px;
  }
}
</style>
<style lang="less">
.talent {
}
</style>
